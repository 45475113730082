import { IContract } from '../types/contract';

const get = (obj: Record<string, any> = {}, path: string | string[]): any => {
  const properties = Array.isArray(path) ? path : path.split('.');
  return properties.reduce((prev: any, curr: any) => prev && prev[curr], obj);
};

export default get;

export const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const getDurationLabel = (number: number) => {
  const hours = Math.trunc(number);
  const minuts = 60 * (number - hours);
  return `${hours}H${minuts > 0 ? minuts.toFixed() : ''}`;
};

export const getWeekNumber = (date: Date) => {
  const startDate = new Date(date);
  const year = startDate && new Date(startDate.getFullYear(), 0, 1);
  const days = Math.floor((startDate.getTime() - year.getTime()) / (24 * 60 * 60 * 1000));
  const week = Math.ceil((startDate.getDay() + 1 + days) / 7);
  return week;
};

export function getActiveContractsBetweenDates(
  contrats : IContract[],
  startDate: Date,
  endDate = null,
) {
  const start = new Date(startDate);
  const end = endDate ? new Date(endDate) : null;
  return contrats.filter((contrat) => {
    const contratStart = new Date(contrat.startDate);
    const contratEnd = contrat.endDate ? new Date(contrat.endDate) : null;
    if (contratEnd && contratEnd < start) {
      return null;
    }
    // Vérifie si le contrat est actif pendant une période entre startDate et endDate
    return (
      contratStart <= (end || new Date())
      && (!contratEnd || contratEnd >= start)
    );
  });
}
