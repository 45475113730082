import React, { useEffect } from 'react';

import { useForm, get, FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { RiDeleteBin2Line } from 'react-icons/ri';
import { ErrorField, InputText } from '../../lib/HooksFormFields';
import { createPoolAction, updatePoolAction } from '../../actions/structures';
import { ILine, IPool } from '../../types/structure';
import styles from './PoolModal.module.scss';

const PoolModal = ({ pool, close }: { pool: IPool, close: () => void }) => {
  const dispatch = useDispatch();
  const {
    control, reset, formState: { errors }, handleSubmit, watch, setValue,
  } = useForm<FieldValues>();

  const form = watch();
  const count = form?.lines?.length || 0;

  async function onSubmit(values: FieldValues) {
    let upatedPool = null;
    if (form._id) {
      const res = await updatePoolAction(dispatch, form._id, values);
      upatedPool = res?.data?.pool;
    } else {
      const res = await createPoolAction(dispatch, values);
      close();
      upatedPool = res?.data?.pool;
    }
    reset(upatedPool);
  }

  useEffect(() => {
    if (pool) {
      reset(pool);
    }
  }, []);

  return (
    <div className={styles.modale}>
      <h2>
        {form?._id ? pool?.name : 'Créer un nouvel équipement'}
      </h2>
      <div className={styles.form}>
       <div className={styles['container-field']}>
          <InputText
            name="name"
            control={control}
            label="Nom de l'équipement"
            rules={{
              required: 'Veuillez renseigner le nom',
            }}
          />
        {typeof get(errors, 'name')?.message === 'string' && (
          <ErrorField message={get(errors, 'name')?.message || ''}/>
        )}
      </div>
      {form?.lines
      && <div className={styles.lines}>
        <h2>
          Lignes
        </h2>
        <div className={styles.input}>
          <p>{count + 1}</p>
          <button
            type="button"
            onClick={() => setValue('lines', [...form.lines, { name: count + 1 }])}
          >
          + Ajouter
          </button>
        </div>
        <div className={styles.list}>
          {form?.lines?.map((l: ILine, i:number) => <div
              key={`line-${i}`}
              className={styles.line}>
              <p>{l.name}</p>
              <button
                type='button'
                onClick={() => setValue('lines', [...form.lines.filter((li: ILine, index: number) => index !== i)])}
              >
                <RiDeleteBin2Line />
              </button>
            </div>)}
        </div>

      </div>
      }
      <div className={styles.actions}>
        <button
          type='button'
          onClick={handleSubmit(onSubmit)}
        >
          {pool._id ? 'Modifier l\'équipment' : 'Créer un nouvel équipement'}
        </button>
      </div>
    </div>
    </div>
  );
};

export default PoolModal;
