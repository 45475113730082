import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { exportPlanningAction, postExportDataAction } from '../../actions/shift';
import ExportButton from '../../components/ExportButton';
import { IShift } from '../../types/shift';
import { ILine, IPole, IPool } from '../../types/structure';
import styles from './export.module.scss';

const Step = ({
  step,
  labels,
  isLast,
  legend,
  shiftWidth,
}: {
  step: IShift,
  labels: any,
  isLast: boolean,
  legend: any,
  shiftWidth: number,
}) => {
  const leftIndex = labels.findIndex((l: any) => l.label === step.slices[0]);
  const color = step.structure.poles.find(
    (p: IPole) => p._id === step.pole,
  )
    ?.activities.find((a: any) => a.value === a.activity)?.color
  || step.structure.poles.find((p: IPole) => p._id === step.pole)?.color
  || legend.find((l:any) => l.value === step.activity)?.color;
  const width = step.slices.length * shiftWidth;

  const activity = legend.find((l: any) => l.value === step.activity)?.label || step.activity;

  return <div className={`${styles.step} ${isLast ? styles.isLast : ''}`}
    style={{
      backgroundColor: color || 'red',
      width,
      left: leftIndex * shiftWidth,
    }}
  >
    <p style={{ backgroundColor: color || 'red' }}>{width < 108 ? `${activity.slice(0, 3)}.` : activity}</p>
  </div>;
};

const ShiftsRow = (
  {
    shifts,
    labels,
    isLast,
    hours = null,
    legend,
    shiftWidth,
  }
  :{
    shifts: IShift[],
    labels: any,
    isLast: boolean
    hours?: number | null,
    legend: any,
    shiftWidth: number,
  },
) => (
    <div className={styles.shifts}>
      <div className={styles.slots}
      >
        {labels?.map((d: any) => {
          const isHour = d.label?.includes(':00');
          const isHalf = (d.label?.includes(':15') || d.label?.includes(':30') || d.label?.includes(':45'));
          return (
            <div
              key={d.label}
              className={`${styles.slot} ${isHour ? styles.hour : ''} ${isHalf ? styles.half : ''}`}
              style={{ width: shiftWidth }}
            >
            </div>
          );
        })}
        {shifts.map((s: IShift) => <Step
          key={s._id}
          step={s}
          labels={labels}
          isLast={isLast}
          legend={legend}
          shiftWidth={shiftWidth}
        />)}
      </div>
      <div className={styles.count}>
        {hours && <p>{hours}</p>}
      </div>
    </div>
);

const Export = ({ isPreview = false }:{ isPreview?: boolean }) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [elements, setElements] = useState([]);
  const [legend, setLegend] = useState([]);
  const [fileName, setFileName] = useState<string>('planning.pdf');
  const { token } = params;
  const structure = searchParams.get('structure');
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const poles = searchParams.get('poles');
  const [shiftWidth, setShiftWidth] = useState(6);
  const downloadFile = async () => {
    const res = await exportPlanningAction(dispatch, {
      structure,
      startDate,
      poles: poles?.split(','),
      endDate,
    }, fileName);
    return res;
  };

  const getExportData = async () => {
    if (token && startDate && structure) {
      const res = await postExportDataAction(dispatch, token, {
        structure,
        startDate,
        poles: poles?.split(','),
        endDate,
      });
      if (res.data) {
        const structureName = res.data?.structure?.name;
        const legendData = res.data?.legend;
        setLegend(legendData);
        const pools = res.data?.pools;
        const users = res.data?.users;
        const rows : any = [];
        let countRow = 0;
        const header = {
          type: 'header',
          label: `PLANNING ${structureName} - `,
        };

        const dateSpace = {
          type: 'space',
        };

        // const breakPage = {
        //   type: 'break',
        // };

        const dates : any = [];
        const shifts = res.data?.shifts;
        const labels = res.data?.hours;
        setShiftWidth(1500 / labels.length);
        if (endDate) {
          let current = 0;
          const end = 6;
          while (current <= end) {
            const newDate = new Date(new Date(startDate)
              .setDate(new Date(startDate).getDate() + current));
            dates.push({
              date: newDate,
              dateLabel: new Intl.DateTimeFormat('fr', {
                weekday: 'short',
                month: 'long',
                day: 'numeric',
              }).format(newDate),
              labels,
              shifts: shifts?.filter(
                (s: IShift) => new Date(s.date).toISOString() === newDate.toISOString(),
              ),
            });
            if (!endDate) {
              current = 6;
            } else {
              current += 1;
            }
          }
        } else {
          const newDate = new Date(startDate);
          dates.push({
            date: newDate,
            dateLabel: new Intl.DateTimeFormat('fr', {
              weekday: 'short',
              month: 'long',
              day: 'numeric',
            }).format(newDate),
            labels,
            shifts: shifts?.filter(
              (s: IShift) => new Date(s.date).toISOString() === newDate.toISOString(),
            ),
          });
        }
        let name = 'planning';
        if (dates.length > 1) {
          const endDay = new Intl.DateTimeFormat('fr', {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }).format(dates[dates.length - 1].date);
          header.label += `${dates[0].dateLabel} au ${endDay}`;
          name += `-${dates[0].dateLabel}-au-${endDay}.pdf`;
        } else {
          const day = new Intl.DateTimeFormat('fr', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }).format(new Date(startDate));
          header.label += `${day}`;
          name += `-${day}.pdf`;
        }
        setFileName(name.replaceAll(' ', '-'));
        rows.push(header);
        countRow = 3;
        dates.forEach((day: any, i: number) => {
          if (i > 0) {
            // rows.push(breakPage);
            rows.push(dateSpace);
          }
          const dayLabel = {
            type: 'label',
            day: day.dateLabel,
            labels,
          };
          rows.push(dayLabel);
          countRow += 1;
          pools.forEach((p: IPool) => {
            const pool : any = {
              type: 'pool',
              pool: p.name,
              lines: [],
            };
            p.lines.forEach((l: ILine) => {
              pool.lines.push({
                label: l.name,
                key: `${l.name}-${day.dateLabel}`,
                shifts: day?.shifts?.filter((s:IShift) => s.line === l._id),
                labels,
              });
            });

            countRow += (pool.lines.length + users.length);
            console.log(countRow * 4 > 248);
            // if (countRow * 4 > 240) {
            //   rows.splice(rows.length - 1, 0, header);
            //   rows.splice(rows.length - 2, 0, breakPage);
            //   countRow = 3;
            // }
            rows.push(pool);
          });
          users.forEach((u: any, userIndex: number) => {
            const absencesOptions = u.contract?.isAnnualized ? ['paid vacation'] : [];
            const user : any = {
              type: 'user',
              user: `${u?.profile?.firstName} ${u?.profile?.lastName}`,
              shifts: day?.shifts?.filter((s:IShift) => s.user === u._id),
              labels,
              isLast: userIndex === users.length - 1,
            };
            const slices = day?.shifts?.filter(
              (s:IShift) => s.user === u._id
              && !absencesOptions.find((a: string) => a === s.activity),
            ).map((s: IShift) => s.slices);
            const hours = slices.flat().length / 12;
            if (hours > 0) {
              const minutes = (hours - Math.floor(hours)) * 60;
              user.hours = `${Math.floor(hours)}H${minutes > 0 ? minutes.toFixed() : ''}`;
            }
            rows.push(user);
          });
        });
        setElements(rows);
      }
    }
  };

  useEffect(() => {
    if (token && startDate && structure) {
      getExportData();
    }
  }, []);
  return (
    <div className={`${styles.container} ${isPreview ? styles.preview : ''}`}>
      <div id="export" className={styles.export}>
        {elements.map((element : any, i: number) => <div
          key={`element-${i}`}
          >
          {element.type === 'header'
            && <div className={styles.header}>
              <h2>{element.label}</h2>
            </div>
          }
          {element.type === 'label'
            && <div className={styles.label}>
              <div className={styles.day}>
                {element.day}
              </div>
              <div className={styles.hours}>
                {element?.labels?.filter((l: any) => l.label !== '02:00').map((d: any) => {
                  const splited = d.label.split(':');
                  let hour = d.label?.includes(':00') && splited[0];
                  if (hour && hour.charAt(0) === '0') {
                    hour = hour.slice(1);
                  }
                  if (hour) {
                    hour += 'H';
                  }
                  let minutes = d.label?.includes(':30') && d.label;
                  if (minutes && minutes.charAt(0) === '0') {
                    minutes = minutes.slice(1);
                  }
                  const isActive = (d.label?.includes(':00') || d.label?.includes(':15') || d.label?.includes(':30') || d.label?.includes(':45'));
                  return (
                    <div
                      key={d.label}
                      className={`${styles.slot} ${isActive ? styles.active : ''}`}
                      style={{ width: shiftWidth }}
                    >
                      {hour && <p className={styles.hour}>{hour}</p>}
                      {minutes && <p className={styles.minutes}>{minutes}</p>}
                    </div>
                  );
                })}
              </div>
            </div>
          }
          {element.type === 'pool'
            && <div className={styles['pool-row']}>
              <div className={styles.pool}>
                <p>{element.pool}</p>
              </div>
              <div className={styles.lines}>
                {element.lines.map((l: any, lineIndex: number) => <div
                  key={l.key}
                  className={styles.line}
                >
                  <div className={styles['line-label']}>
                    <p>{l.label}</p>
                  </div>
                  <div className={styles.shifts}>
                    <ShiftsRow
                      shifts={l.shifts}
                      labels={l.labels}
                      isLast={lineIndex === element.lines.length - 1 }
                      legend={legend}
                      shiftWidth={shiftWidth}
                    />
                  </div>
                </div>)}
              </div>
            </div>
          }
          {element.type === 'user'
            && <div className={`${styles['user-row']} ${element.isLast ? styles.isLast : ''}`}>
                <div className={styles.user}>
                  <p>{element.user}</p>
                </div>
                <div className={styles.shifts}>
                  <ShiftsRow
                    shifts={element.shifts}
                    labels={element.labels}
                    isLast={true}
                    hours={element?.hours}
                    legend={legend}
                    shiftWidth={shiftWidth}
                  />
                </div>
            </div>
          }
          {element.type === 'break'
            && <div className={styles.break}/>
          }
          {element.type === 'space'
            && <div className={styles.space}/>
          }
        </div>)}

      </div>
      {isPreview
        && <>
            <div className={styles.footer}>
          <span>Légende</span>
          <div className={styles.items}>
            {legend.filter(
              (a: any) => a.value !== 'rest'
              && a.value !== 'sick'
              && a.value !== 'Leave without pay'
              && a.value !== 'conventional leave'
              && a.value !== 'other'
              && a.value !== 'dress up day'
              && a.value !== 'unjustified absence',
            ).map((l : any) => <div
              key={l.value}
              className={styles.item}
            >
            <div
              className={styles.color}
              style={{
                backgroundColor: l.color,
              }}
            >
            </div>
            <p>{l.label}</p>
            </div>)}
          </div>
        </div>
          <div className={styles.download}>
          <ExportButton
          callBack={downloadFile}
          />
        </div>
        </>
      }
    </div>
  );
};

export default Export;
