import React, { useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAction, signIn } from '../../actions/auth';
import img from '../../assets/images/login.svg';
import SignInForm from '../../components/form/AuthForm/SignInForm';

import styles from './signin.module.scss';

const SignIn = () => {
  const { user, error } = useSelector((d: any) => d.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getProfileAction(dispatch);
    }
  }, []);

  useEffect(() => {
    if (user?.role === 'Admin' || user?.role === 'Owner' || user?.role === 'Manager') {
      navigate('/admin/planning');
    }
    if (user?.role === 'Member') {
      navigate('/member/home');
    }
  }, [user]);

  function submit(data: FieldValues) {
    signIn(dispatch, data);
  }

  return (
    <div className={styles.signin}>
      <div className={styles.picture}>
        <img src={img} alt="logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.form}>
          <h1>OMADA</h1>
          <h3>Se connecter</h3>
          <SignInForm
            forgotPwdLink="/forgot-password"
            errorMessage={error}
            submit={submit}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
