/* eslint-disable import/no-extraneous-dependencies */
import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import fileDownload from 'js-file-download';
import { format } from 'date-fns';
import {
  API_URL, deleteData, errorHandler, getData, postData,
} from '.';
import {
  GET_ALL_SHIFTS,
  GET_SHIFTS_LIST,
  POST_SHIFT,
  SHIFT_ERROR,
  SHIFT_LOADING,
  GET_MEMBER_HOME,
  POST_SHIFT_VALIDATION,
  POST_WEEKS_OPTIONS,
  SET_TOAST,
  POST_WEEK,
  DELETE_WEEK,
  POST_SHIFT_ERROR,
  POST_SHIFT_ALERT,
} from './types';

export const getShiftsListAction = async (dispatch: Dispatch, structure: null | string = null) => {
  let url = '/shifts/list';
  if (structure) {
    url += `?structure=${structure}`;
  }
  dispatch({
    type: SHIFT_LOADING,
  });
  const response = await getData(SHIFT_ERROR, url, dispatch, true);
  if (response?.data?.list) {
    const list = {
      ...response?.data?.list,
    };
    dispatch({
      type: GET_SHIFTS_LIST,
      payload: list,
    });
  }
};

export const postShiftsFilteredAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts/filtered';
  const payload = { ...data };
  dispatch({
    type: SHIFT_LOADING,
  });
  if (data?.startDate) {
    const startDate = new Date(`${format(data?.startDate, 'yyyy-MM-dd')}T00:00:00.000Z`).toISOString();
    payload.startDate = startDate;
  }
  const response = await postData(SHIFT_ERROR, url, dispatch, payload, true);
  if (response?.data?.shifts) {
    dispatch({
      type: GET_ALL_SHIFTS,
      payload: response.data.shifts,
    });
  }
};

export const postPublishWeekAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts/publish';
  const payload = { ...data };
  dispatch({
    type: SHIFT_LOADING,
  });
  const response = await postData(SHIFT_ERROR, url, dispatch, payload, true);
  if (response?.data?.shifts) {
    dispatch({
      type: GET_ALL_SHIFTS,
      payload: response.data.shifts,
    });
  }
};

export const postShiftsAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts';
  dispatch({
    type: SHIFT_LOADING,
  });
  dispatch({
    type: POST_SHIFT_ERROR,
    payload: null,
  });
  dispatch({
    type: POST_SHIFT_ALERT,
    payload: null,
  });

  const response = await postData(POST_SHIFT_ERROR, url, dispatch, data, true);
  if (response?.data?.shifts) {
    dispatch({
      type: POST_SHIFT,
      payload: {
        shifts: response.data.shifts,
        data,
      },
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'le créneau a été enregistré',
        type: 'success',
      },
    });
  }
  if (response?.data?.errors) {
    dispatch({
      type: POST_SHIFT_ERROR,
      payload: response.data.errors,
    });
  }
  if (response?.data?.alerts) {
    dispatch({
      type: POST_SHIFT_ALERT,
      payload: response.data?.alerts,
    });
  }
  return response?.data;
};

export const getMemberHomeAction = async (dispatch: Dispatch) => {
  const url = '/shifts/today';
  dispatch({
    type: SHIFT_LOADING,
  });
  const response = await getData(SHIFT_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: GET_MEMBER_HOME,
      payload: response.data,
    });
  }
};

export const postShiftValidationAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts/validation';
  dispatch({
    type: SHIFT_LOADING,
  });

  const response = await postData(SHIFT_ERROR, url, dispatch, data, true);
  if (response?.data?.shifts) {
    dispatch({
      type: POST_SHIFT_VALIDATION,
      payload: response?.data?.shifts,
    });
  }
};

export const saveCurrentDayShiftAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts/save';
  dispatch({
    type: SHIFT_LOADING,
  });

  const response = await postData(SHIFT_ERROR, url, dispatch, data, true);
  if (response?.data?.shifts) {
    dispatch({
      type: POST_SHIFT,
      payload: {
        shifts: response.data.shifts,
        data,
      },
    });
  }
};

export const deleteDayShiftAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts/delete';
  dispatch({
    type: SHIFT_LOADING,
  });

  const response = await postData(SHIFT_ERROR, url, dispatch, data, true);
  if (response?.data?.shifts) {
    dispatch({
      type: POST_SHIFT,
      payload: {
        shifts: response.data.shifts,
        data,
      },
    });
  }
};

export const getWeekOptionsAction = async (dispatch: Dispatch, structureId: string) => {
  const url = `/week/${structureId}`;
  const response = await getData(SHIFT_ERROR, url, dispatch, true);
  if (response?.data?.weeks) {
    dispatch({
      type: POST_WEEKS_OPTIONS,
      payload: response?.data.weeks,
    });
  }
};

export const postFavoriteWeekAction = async (dispatch: Dispatch, data: any) => {
  const url = '/week/';
  const response = await postData(SHIFT_ERROR, url, dispatch, data, true);
  if (response?.data?.week) {
    dispatch({
      type: POST_WEEK,
      payload: response?.data?.week,
    });
  }
};

export const deleteFavoriteWeekAction = async (dispatch: Dispatch, id: string) => {
  const url = `/week/${id}`;
  const response: any = await deleteData(SHIFT_ERROR, url, dispatch, true);
  if (response?.data) {
    dispatch({
      type: DELETE_WEEK,
      payload: {
        _id: id,
      },
    });
  }
};

export const importWeekAction = async (dispatch: Dispatch, data: any) => {
  const url = '/shifts/import';
  const response = await postData(SHIFT_ERROR, url, dispatch, data, true);
  if (response?.data?.shifts) {
    dispatch({
      type: GET_ALL_SHIFTS,
      payload: response.data.shifts,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        message: 'la semaine à été importée',
        type: 'success',
      },
    });
  }
};

export const postExportDataAction = async (dispatch: Dispatch, token: string, data: any) => {
  const url = '/shifts/export/data';
  const requestUrl = API_URL + url;
  const config: Record<string, any> = {
    headers: {
      Authorization: `JWT ${token}`,
    },
  };
  let res: AxiosResponse | null = null;
  let error = null;
  try {
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, SHIFT_ERROR);
  }
  return res?.status === 200 || res?.status === 201 ? res : error as AxiosResponse;
};

export const exportPlanningAction = async (dispatch: Dispatch, data: any, fileName: string = 'planning.pdf') => {
  const url = '/shifts/export';
  const requestUrl = API_URL + url;
  const token = localStorage.getItem('token');
  const config: Record<string, any> = {
    headers: {
      Authorization: `${token}`,
    },
    responseType: 'blob',
  };
  let res: AxiosResponse | null = null;
  try {
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    errorHandler(dispatch, err, SHIFT_ERROR);
  }
  if (res?.data) {
    fileDownload(res?.data, fileName);
  }
  return res;
};
