import React, { useEffect } from 'react';
import { useForm, get } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getWeekOptionsAction, importWeekAction } from '../../actions/shift';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import { FavioriteWeek } from '../../types/shift';
import styles from './ImportModal.module.scss';

const ImportModal = ({ close }:{ close: () => void }) => {
  const {
    watch, reset, control, handleSubmit, formState: { errors },
  } = useForm<any>();
  const dispatch = useDispatch();
  const form = watch();
  const { favioritesWeeks } = useSelector((store: any) => store.shiftsReducer);
  const { filters } = useSelector((store: any) => store.usersReducer);

  useEffect(() => {
    getWeekOptionsAction(dispatch, filters.structure);
  }, [filters.structure]);

  useEffect(() => {
    if (filters) {
      reset({
        currentWeek: {
          startDate: filters.startDate,
          endDate: filters.endDate,
        },
        structure: filters.structure,
      });
    }
  }, [filters]);

  async function onSubmit() {
    await importWeekAction(dispatch, form);
    return close();
  }

  return (
    <div className={styles.modale}>
      <h2>
        Importer une semaine
      </h2>
      {favioritesWeeks?.length > 0
        ? <>
        <div className={styles.field}>
          <label>Choisir une semaine</label>
          <StyledSelect
            name="importWeek"
            control={control}
            rules={{
              required: 'la semaine est requise',
            }}
            options={favioritesWeeks.map((w: FavioriteWeek) => ({
              label: w.label,
              value: {
                startDate: w.startDate,
                endDate: w.endDate,
              },
            }))}
          />
          {typeof get(errors, 'importWeek')?.message === 'string' && (
            <ErrorField message={get(errors, 'importWeek')?.message || ''}/>
          )}
        </div>
        <div className={styles.actions}>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            className={styles.add}
          >
            Importer
          </button>
        </div>
      </>
        : <div className={styles.error}>
          <p>Vous n&apos;avez pas de semaines en favoris.</p>
        </div>
      }
    </div>
  );
};

export default ImportModal;
