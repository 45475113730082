import React, { useEffect, useRef } from 'react';
import { BsFillBellFill } from 'react-icons/bs';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useInterval } from 'usehooks-ts';
import styles from './Nav.module.scss';

import calendar from './images/cal.svg';
import sites from './images/sites.svg';
import users from './images/users.svg';
import notif from './images/notif.svg';
import home from './images/home.svg';
import logo from '../../assets/images/logo.svg';
import { getProfileAction } from '../../actions/auth';
import { getNotifsAction } from '../../actions/notif';
import { SET_NOTIF_IS_OPEN } from '../../actions/types';
import { INotif } from '../../types/notif';
import Notifs from '../Notifs/Notifs';
import ExportModal from '../ExportModal/ExportModal';
import useWindowSize from '../../hooks/useWindowSize';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';

export function NavAdmin() {
  const { width } = useWindowSize();
  const isMobile = width && width < 1024;
  const { user } = useSelector((d: any) => d.authReducer);
  const { notifs, isOpen } = useSelector((d: any) => d.notifsReducer);
  const {
    list,
  } = useSelector((store: any) => store.shiftsReducer);
  const newNotifs = notifs.filter((n: INotif) => !n.isRead)?.length;
  const exportModalRef = useRef<IModalHandle>(null);

  const dispatch = useDispatch();
  useInterval(
    () => {
      getNotifsAction(dispatch);
    },
    // Delay in milliseconds or null to stop it
    300000,
  );
  useEffect(() => {
    getProfileAction(dispatch);
    getNotifsAction(dispatch);
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_NOTIF_IS_OPEN,
      payload: false,
    });
  }, [isMobile]);

  return (
    <>
      <nav className={`${styles.mobile}`}>
        <NavLink
          to="/admin/planning"
          className={(l) => (!l.isActive ? '' : styles.active)}
          >
          <img src={calendar} alt="calendrier" />
          Planning
        </NavLink>
        {user?.structure?._id && user?.role === 'Manager'
          && <NavLink
                  to={`/admin/site/${user.structure?._id}`}
                  className={(l) => (!l.isActive ? '' : styles.active)}
                  >
                  <img src={sites} alt="sites" />
                  Site
                </NavLink>
         }
         {(user?.role === 'Admin' || user?.role === 'Owner') && <NavLink
            to="/admin/site"
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            <img src={sites} alt="sites" />
            Sites
          </NavLink>
          }
         {(user?.role === 'Admin' || user?.role === 'Owner')
          && <NavLink
            to="/admin/user"
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            <img src={users} alt="membres" />
            Membres
          </NavLink>
        }
        <NavLink
          to="/admin/notif"
          className={(l) => (!l.isActive ? '' : styles.active)}
          >
          <img src={notif} alt="notif" />
          Notifs
          <div className={`${styles.count} ${newNotifs > 0 ? styles.active : ''}`}>
            {newNotifs}
          </div>
        </NavLink>
      </nav>
      <nav className={`${styles.desktop}`}>
        <div className={styles.nav}>
          <img src={logo} alt="oiikos" />
          <NavLink
            to="/admin/planning"
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            Planning
          </NavLink>
          {user?.structure?._id && user?.role === 'Manager'
          && <NavLink
                to={`/admin/site/${user.structure?._id}`}
                className={(l) => (!l.isActive ? '' : styles.active)}
                >
                Site
              </NavLink>
          }
          {(user?.role === 'Admin' || user?.role === 'Owner') && <NavLink
            to="/admin/site"
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            Sites
          </NavLink>
          }
          {(user?.role === 'Admin' || user?.role === 'Owner') && <NavLink
            to="/admin/user"
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            Membres
          </NavLink>
          }
        </div>
        <div className={styles.user}>
          {user?.role === 'Admin' && exportModalRef && list
            && <button
              type="button"
              className={styles.export}
              onClick={() => exportModalRef?.current?.open()}
            >
              Exports
            </button>
          }
          <Link to="/admin/profile">
            <p>{user?.profile?.firstName} {user?.profile?.lastName}</p>
            <div className={styles.profile}>
              {user?.picture?.path
                ? <>
                  <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture?.path}`} alt='avatar' />
                </>
                : <p>{user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}</p>
              }
            </div>
          </Link>
          <button
            type='button'
            className={styles.notifs}
            onClick={() => dispatch({
              type: SET_NOTIF_IS_OPEN,
              payload: !isOpen,
            })}
          >
            <BsFillBellFill />
            <div className={`${styles.count} ${newNotifs > 0 ? styles.active : ''}`}>
              {newNotifs}
            </div>
          </button>
        </div>
      </nav>
      <div className={`${styles['notifs-container']} ${isOpen ? styles.active : ''}`}>
        <Notifs />
      </div>
      <ModalStandalone ref={exportModalRef}>
        <ExportModal
        />
      </ModalStandalone>
    </>
  );
}

export function NavMember() {
  const { width } = useWindowSize();
  const isMobile = width && width < 1024;
  const { user } = useSelector((d: any) => d.authReducer);
  const { notifs, isOpen } = useSelector((d: any) => d.notifsReducer);
  const newNotifs = notifs.filter((n: INotif) => !n.isRead)?.length;
  const dispatch = useDispatch();
  useInterval(
    () => {
      getNotifsAction(dispatch);
    },
    // Delay in milliseconds or null to stop it
    300000,
  );
  useEffect(() => {
    getProfileAction(dispatch);
    getNotifsAction(dispatch);
  }, []);

  useEffect(() => {
    dispatch({
      type: SET_NOTIF_IS_OPEN,
      payload: false,
    });
  }, [isMobile]);

  return (
    <>
      <nav className={`${styles.mobile} ${styles.member}`}>
        <NavLink
          to="/member/home"
          className={(l) => (!l.isActive ? '' : styles.active)}
          >
          <img src={home} alt="home" />
          Accueil
        </NavLink>
        <NavLink
          to={`/member/planning/${user._id}`}
          className={(l) => (!l.isActive ? '' : styles.active)}
          >
          <img src={calendar} alt="calendrier" />
          Planning
        </NavLink>
        <NavLink
          to="/member/notif"
          className={(l) => (!l.isActive ? '' : styles.active)}
          >
          <img src={notif} alt="notif" />
          Notif
          <div className={`${styles.count} ${newNotifs > 0 ? styles.active : ''}`}>
            {newNotifs}
          </div>
        </NavLink>
      </nav>
      <nav className={`${styles.desktop}`}>
        <div className={styles.nav}>
          <img src={logo} alt="oiikos" />
          <NavLink
            to="/member/home"
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            Accueil
          </NavLink>
          <NavLink
            to={`/member/planning/${user._id}`}
            className={(l) => (!l.isActive ? '' : styles.active)}
            >
            Planning
          </NavLink>
        </div>
        <div className={styles.user}>
          <Link to="/member/profile">
            <p>{user?.profile?.firstName} {user?.profile?.lastName}</p>
            <div className={styles.profile}>
              {user?.picture?.path
                ? <>
                  <img src={`${process.env.REACT_APP_API_URL}/public/${user.picture?.path}`} alt='avatar' />
                </>
                : <p>{user?.profile?.firstName[0]}.{user?.profile?.lastName[0]}</p>
              }
            </div>
          </Link>
          <button
            type='button'
            className={styles.notifs}
            onClick={() => dispatch({
              type: SET_NOTIF_IS_OPEN,
              payload: !isOpen,
            })}
          >
            <BsFillBellFill />
            <div className={`${styles.count} ${newNotifs > 0 ? styles.active : ''}`}>
              {newNotifs}
            </div>
          </button>
        </div>
      </nav>
      <div className={`${styles['notifs-container']} ${isOpen ? styles.active : ''}`}>
        <Notifs />
      </div>
    </>
  );
}
